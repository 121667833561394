import React from "react";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/system";
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { Check } from "@mui/icons-material";
import { LoaderComponent } from "../components/LoaderComponent";
import useInconformidad from "../hooks/inconformidad/useInconformidad";
import useInconformidadViews from "../hooks/inconformidad/useInconformidadViews";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#6F3439",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#6F3439",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#74C1ED",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "#ffc3d0",
  },
}));

const InconformidadView = () => {
  const {
    steps,
    activeStep,
    newStep,
    loader,
    inputValues,
    handleBack,
    handleNext,
    sendComplaint,
    identificacionPaciente,
    identificacionRepresentante,
    credencialDerechoHabliente,
    recetasRelacionadas,
    estudiosRelacionados,
    otroDocumentoR,
    setFilesIdentificacionP,
    setFilesIdentificacionR,
    setFilesCredencialDerechoHabiente,
    setFilesRecetasRelacionadas,
    setFilesestudiosRelacionados,
    setFilesOtroDocumentoR,
    ArrayFiles1,
    ArrayFiles2,
    ArrayFiles3,
    ArrayFiles4,
    ArrayFiles5,
    ArrayFiles6,
    handleChange,
  } = useInconformidad();

  const { getCardContent } = useInconformidadViews(
    activeStep,
    inputValues,
    identificacionPaciente,
    identificacionRepresentante,
    credencialDerechoHabliente,
    recetasRelacionadas,
    estudiosRelacionados,
    otroDocumentoR,
    setFilesIdentificacionP,
    setFilesIdentificacionR,
    setFilesCredencialDerechoHabiente,
    setFilesRecetasRelacionadas,
    setFilesestudiosRelacionados,
    setFilesOtroDocumentoR,
    ArrayFiles1,
    ArrayFiles2,
    ArrayFiles3,
    ArrayFiles4,
    ArrayFiles5,
    ArrayFiles6,
    handleChange
  );

  return (
    <>
      {loader && <LoaderComponent />}
      <div className="container_Formulario bg-prueba">
        <div className="container d-flex justify-content-center pb-4 pt-4">
          <Box sx={{ flexDirection: "row" }}>
            <Stepper
              activeStep={newStep}
              alternativeLabel
              connector={<QontoConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel
                    sx={{
                      "& .MuiStepLabel-label": {
                        color: "#6F3439",
                      },
                    }}
                    StepIconComponent={QontoStepIcon}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </div>
        <div className="d-flex justify-content-center">
          <Card className="card_form">
            <CardContent>
              {getCardContent()}
              {inputValues.showValidate && (
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ color: "red" }}
                >
                  Completa todos los campos
                </Typography>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant={activeStep === 0 ? "outlined" : "contained"}
                  sx={{
                    backgroundColor: activeStep === 0 ? "inherit" : "#6F3439",
                    marginTop: 5,
                    height: "40px",
                    ":hover": { bgcolor: "#6F3439" },
                  }}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Atras
                </Button>
                {activeStep < 7 ? (
                  <Button
                    onClick={handleNext}
                    variant="contained"
                    sx={{
                      backgroundColor: "#6F3439",
                      marginTop: 5,
                      marginLeft: "20%",
                      height: "40px",
                      ":hover": { bgcolor: "#6F3439" },
                    }}
                  >
                    Siguiente
                  </Button>
                ) : (
                  <Button
                    onClick={sendComplaint}
                    variant="contained"
                    sx={{
                      backgroundColor: "#6F3439",
                      marginLeft: "20%",
                      marginTop: 5,
                      height: "40px",
                      ":hover": { bgcolor: "#6F3439" },
                    }}
                  >
                    Enviar
                  </Button>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
};

export default InconformidadView;
