import React, { useContext, useState, useRef } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import { NotificacionContext } from "../context/notificacion_context";
import { Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import ReadingProgress from "../components/ReadingProgress";
import { useDispatch, useSelector } from "react-redux";
import { clearState } from "../reducer/global_reducer";

const style = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  mb: 2,
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
};

const HomeView = () => {
  const [state, setState] = useState({
    openModal: false,
  });
  /* const [stateTwo, setStateTwo] = useState({
    openModalTwo: false,
  }); */

  const dispatch = useDispatch();
  const readingProgress = useSelector((state) => state.readingProgress);

  const [aceptarTerminos, setAceptarTerminos] = useState(false);
  /* const [aceptarTerminos2, setAceptarTerminos2] = useState(false); */

  const pdfUrl = `${process.env.PUBLIC_URL}/ACEPTACION_DEL_PROCEDIMIENTO.pdf`;
  /* const pdf2Url = `${process.env.PUBLIC_URL}/AVISO_DE_PRIVACIDAD.pdf`; */

  const pdfContainerRef = useRef(null);

  const navigate = useNavigate();
  const { showNotification } = useContext(NotificacionContext);

  const handleClose = () => {
    setState({ ...state, openModal: false });
  };

  const handleChangeChecked = (e) => {
    setAceptarTerminos(e.target.checked);
    if (!aceptarTerminos) {
      dispatch(clearState());
      navigate("/presentarInconformidad");
    } else {
      showNotification(
        "warning",
        "Por favor acepta Términos y condiciones",
        "No puedes continuar en el proceso de inconformidad",
        null,
        4
      );
    }
  };

  /*const handleCloseTwo = () => {
    setStateTwo({ ...stateTwo, openModalTwo: false });
  };

    const handleChangeChecked = (e) => {
    setAceptarTerminos(e.target.checked);
    if (!aceptarTerminos) {
      dispatch(clearState());
      setStateTwo({ ...stateTwo, openModalTwo: true });
    } else {
      showNotification(
        "warning",
        "Por favor acepta Términos y condiciones",
        "No puedes continuar en el proceso de inconformidad",
        null,
        4
      );
    }
  };
  const handleChangeCheckedTwo = (e) => {
    setAceptarTerminos2(e.target.checked);
    if (!aceptarTerminos2) {
      dispatch(clearState());
      navigate("/presentarInconformidad");
    } else {
      showNotification(
        "warning",
        "Por favor acepta Términos y condiciones",
        "No puedes continuar en el proceso de inconformidad",
        null,
        4
      );
    }
  }; */

  return (
    <div className="bg-prueba container_Home ">
      <section className="contentGrid1 ms-2">
        <div className="contentDesign">
          <img
            className="imageHome "
            src={require("../assets/images/Header.png")}
          />
        </div>

        <div className="cardHome">
          <p className="textHome1 text-triptico">
            Encuentra la <span className="resaltado">solución</span> a tus{" "}
            <span className="resaltado">inconformidades</span> en la{" "}
            <span className="resaltado">prestación</span> de{" "}
            <span className="resaltado">servicios </span> de{" "}
            <span className="resaltado"> salud</span>
          </p>
        </div>
        <div className="secondCardHome">
          <p
            className="textHome2"
            style={{ fontSize: "1rem", marginBottom: "10px", marginTop: "-5%" }}
          >
            {" "}
            <span className="resaltado">Debes contar con:</span>
          </p>
          <div className="divider"></div>
          <p
            className="textHome2"
            style={{ fontSize: ".9rem", paddingTop: "4.5%" }}
          >
            • Identificación oficial vigente del afectado y de quien presenta la
            queja.
            <br />
            <br />• Si es derechoabiente del ISSSTE, IMSS, IMSS Bienestar,
            Hospitalarios privados o cualquier otra institución prestadora de
            servicios médicos, la credencial y/ o documento que demuestre la
            vigencia.
            <br />
            <br />• Recetas médicas, estudios de laboratorio y gabinete.
            <br />
            <br />• Recibos de honorarios, laboratorios y medicamentos.
            <br />
            <br />• Cualquier otro documento.
          </p>
        </div>
        <div className="ctaHomeContain">
          <div
            className="ctaHome"
            onClick={() => {
              dispatch(clearState());
              setState({ ...state, openModal: true });
            }}
          ></div>
        </div>
      </section>

      <Modal
        open={state.openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-big text-bold boderBottom--guinda">
            Términos y condiciones.
          </div>

          <div
            className="h-100 d-flex justify-content-center align-items-center pt-3 pb-3"
            style={{ overflowY: "auto" }}
            ref={pdfContainerRef}
          >
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
              <ReadingProgress fileUrl={pdfUrl} />
            </Worker>
          </div>
          <div className="text-center">
            <FormControlLabel
              required
              control={
                <Checkbox
                  checked={aceptarTerminos}
                  onChange={handleChangeChecked}
                  disabled={readingProgress < 90}
                />
              }
              sx={{
                color: "#6F3439",
                "& .MuiFormControlLabel-label": {
                  fontSize: "1.2rem",
                },
              }}
              label="Aceptar términos y condiciones"
            />
          </div>
        </Box>
      </Modal>

      {/* <Modal
        open={stateTwo.openModalTwo}
        onClose={handleCloseTwo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-big text-bold boderBottom--guinda">NUEVO.</div>

          <div
            className="h-100 d-flex justify-content-center align-items-center pt-3 pb-3"
            style={{ overflowY: "auto" }}
            ref={pdfContainerRef}
          >
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
              <ReadingProgress fileUrl={pdf2Url} />
            </Worker>
          </div>
          <div className="text-center">
            <FormControlLabel
              required
              control={
                <Checkbox
                  checked={aceptarTerminos2}
                  onChange={handleChangeCheckedTwo}
                  disabled={readingProgress < 90}
                />
              }
              sx={{
                color: "#6F3439",
                "& .MuiFormControlLabel-label": {
                  fontSize: "1.2rem",
                },
              }}
              label="Aceptar términos y condiciones"
            />
          </div>
        </Box>
      </Modal> */}
    </div>
  );
};

export default HomeView;
