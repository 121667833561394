import { Route, Navigate } from 'react-router-dom';



// Este componente verifica si el usuario está autenticado
const PrivateRoutes = ({children}) => {
    const token = localStorage.getItem('token');
    if (token !== null) {
        return children
    } else {
        return <Navigate to="/login" replace={true} />
    }
}
export default PrivateRoutes