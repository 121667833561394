import Axios from "axios";
import Swal from "sweetalert2";


export const token = () => {
    return window.localStorage.getItem('token');
}

export const config = () => {
    let token = window.localStorage.getItem('token');
    return {
        headers: {
            "Authorization": `Bearer ${token}`,
        }
    }
}

export const settings = () => {
    return {
        headers: {
            "Content-Type": "application/json",
        }
    }
}



const axios = Axios.create({
    baseURL: process.env.REACT_APP_URLAPI,
});

export const services = (method, service, body) => {

    

    switch (method) {
        case "GET":

            let url = `${service}`;

            return axios.get(url, config()).then((r) => {
                return response(r)
            }).catch((err) => {
                return response(err.response);
            });


        case "POST":

            return axios.post(`${service}`, body, config()).then((r) => {
                return response(r);
            }).catch((err) => {
                return response(err.response);
            });


        case "PUT":
            return axios.put(`${service}`, body, config()).then((r) => {
                return response(r);
            }).catch((err) => {
                return response(err.response);
            });

        case "DELETE":
            return axios.delete(`${service}`, config()).then((r) => {
                return response(r);
            }).catch((err) => {
                return response(err.response);
            });

        default:
            break;
    }
   
}




const response = (r) => {
    
    if (r === undefined) {
        return false;
    }

    if (r.status === 401) {

        if(localStorage.token){
            Swal.fire({
                title: 'Sesión Expirada',
                text: 'Por favor, inicie sesion de nuevo.',
                confirmButtonColor: '#6f3439',
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false,
                reverseButtons:true
    
            }).then((result) => {
                localStorage.clear()
                window.location.href = '/login'
                return false
            })
        } else{
            const Toast = Swal.mixin({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.onmouseenter = Swal.stopTimer;
                  toast.onmouseleave = Swal.resumeTimer;
                }
              });
            Toast.fire({
                icon: "error",
                title: "Credenciales Invalidas"
              });
        }

        
        return false
    }

    if (r.status === 200) {
        return { status: r.status, data: r.data }
    }
    if (r.status === 400) {
        return { status: r.status, data: r.data }
    }

    if (r.status === 500) {
        window.location.href = '/500';

        return { status: r.status }
    }

    

    return { status: r.status, errors: r.data.error }
}