import * as React from "react";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin, ToolbarProps } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import readingIndicatorPlugin from "./readingIndicatorPlugin";

interface ReadingProgressProps {
  fileUrl: string;
  showToolbar?: boolean;
}

const ReadingProgress: React.FC<ReadingProgressProps> = ({
  fileUrl,
  showToolbar = true,
}) => {
  const readingIndicatorPluginInstance = readingIndicatorPlugin();
  const { ReadingIndicator } = readingIndicatorPluginInstance;

  const renderToolbar = React.useCallback(
    (Toolbar: (props: ToolbarProps) => React.ReactElement) => (
      <>
        <Toolbar />
        <div
          style={{
            bottom: "-0.25rem",
            position: "absolute",
            left: 0,
            width: "100%",
          }}
        >
          <ReadingIndicator />
        </div>
      </>
    ),
    []
  );

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          overflow: "auto",
        }}
      >
        <Viewer
          fileUrl={fileUrl}
          plugins={
            showToolbar
              ? [
                  defaultLayoutPlugin({
                    renderToolbar,
                    sidebarTabs: (defaultTabs) => [],
                  }),
                  readingIndicatorPluginInstance,
                ]
              : []
          }
          defaultScale={SpecialZoomLevel.PageWidth} // Ajusta el zoom para encajar el ancho de la página a la pantalla
        />
      </div>
    </div>
  );
};

export default ReadingProgress;
