import { useContext, useState, useEffect } from "react";
import FormularioPaciente from "../components/FormularioPaciente";
import FormularioRepresentante from "../components/FormularioRepresentante";
import FormularioDomicilio from "../components/FormularioDomicilio";
import FormularioInconformidadR from "../components/FormularioInconformidadR";
import { GlobalContext } from "../context/global_context";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewPDFView from "./PreviewPDFView";
import useMediaQuery from "@mui/material/useMediaQuery";

function VerQuejaAdminView() {
  const { globalState, setGlobalState } = useContext(GlobalContext);

  const [activeButton, setActiveButton] = useState("paciente");
  const [activeForm, setActiveForm] = useState("paciente");
  const [pdfs, setPdfs] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  const handleReturnClick = () => {
    navigate("/inconformidadesRecibidas");
  };

  const quejaSeleccionada = location.state || {};

  const formularioPaciente = () => {
    setActiveForm("paciente");
    setActiveButton("paciente");
  };

  const formularioInconformidad = () => {
    setActiveForm("inconformidad");
    setActiveButton("inconformidad");
  };

  const isSmallScreen = useMediaQuery("(max-width:600px)"); 

  const styles = {
    marginLeft: isSmallScreen ? "1rem" : "0rem",
    marginTop: "2.6rem",
    textAlign: isSmallScreen ? "start" : "center",
    textTransform: "uppercase",
    fontWeight: "bold",
  };

  useEffect(() => {    
    const fetchPdfs = async () => {
      const files = [
        { name: "Identificación del paciente", url: quejaSeleccionada.identificacionPaciente },
        { name: "Identificación del representante", url: quejaSeleccionada.identificacionRepresentante },
        { name: "Derechohabiente", url: quejaSeleccionada.derechoHabiente },
        { name: "Copia de recetas", url: quejaSeleccionada.copiaRecetas },
        { name: "Copia de estudios", url: quejaSeleccionada.copiaEstudios },
        { name: "Otro documento", url: quejaSeleccionada.otroDocumento },
      ];
  
      const fetchedPdfs = await Promise.all(
        files.map(async (file) => {
          if (file.url) {

            const response = await fetch(file.url);
            const contentType = response.headers.get("content-type");
  
            if (contentType === "application/pdf") {
              const blob = await response.blob();
              const fileURL = URL.createObjectURL(blob);
              return { name: file.name, file: fileURL };
            }
          }
          return null;
        })
      );
  
      const validPdfs = fetchedPdfs.filter((pdf) => pdf !== null);
      setPdfs(validPdfs);
    };
  
    fetchPdfs();
  }, [quejaSeleccionada]);

  // const handleFolio = (e) => {
  //     setGlobalState({...globalState, folio:e.target.value})
  // }

  // const handleFechaSolicitud = (e) =>{
  //     setGlobalState({...globalState, fechaSolicitud:e.target.value})
  // }

  return (
    <div className="container-fluid bk-img">
      <div className="container">
        <div
          className="cta_redirectInconformidad cta--morado cta--medium mt-4"
          onClick={handleReturnClick}
        >
          <p className="button-text" style={{ color: "white" }}>
            Regresar
          </p>
        </div>
        <div className="titulo py-3 text-morado text-big text-bold">
          Seguimiento de inconformidad
        </div>

        <div>
          {/* <div className=' links mt-3'>
                        <div className='row'>
                            <div className='col'></div>
                            <div className='col d-flex align-items-start justify-content-center '>
                                <Link href="#" underline="always">
                                    {'Informacíon del trámite'}
                                </Link>
                            </div>

                            <div className='col  d-flex align-items-end justify-content-center'>
                                <Link href="#" underline="always">
                                    {'Instrucciones de llenado'}
                                </Link>
                            </div>
                        </div>

                    </div> */}
          <ButtonGroup variant="contained" aria-label="Basic button group">
            <div
              className={`pill-principal boton-paciente ${
                activeButton === "paciente" ? "active-principal" : ""
              }`}
              onClick={() => {
                formularioPaciente();
              }}
            >
              Paciente
            </div>
            <div
              className={`pill-principal boton-datosIconformidad ${
                activeButton === "inconformidad" ? "active-principal" : ""
              }`}
              onClick={formularioInconformidad}
            >
              Inconformidad
            </div>
          </ButtonGroup>
          <div className="card pb-5 cardFormulario">
            {activeForm === "paciente" && (
              <>
                <h4 className="text-guinda" style={styles}>
                  Datos del paciente:
                </h4>
                <FormularioPaciente quejaSeleccionada={quejaSeleccionada} />

                <h4 className="text-guinda" style={styles}>
                  Dirección del paciente:
                </h4>
                <FormularioDomicilio quejaSeleccionada={quejaSeleccionada} />

                <h4 className="text-guinda" style={styles}>
                  Datos del representante:
                </h4>
                <FormularioRepresentante
                  quejaSeleccionada={quejaSeleccionada}
                />
              </>
            )}
            {activeForm === "inconformidad" && (
              <>
                <FormularioInconformidadR
                  quejaSeleccionada={quejaSeleccionada}
                />

                <div
                  style={{
                    marginTop: pdfs.length > 3 ? "8rem" : "0",
                    marginBottom: pdfs.length > 3 ? "8rem" : "0",
                    padding: "10px",
                  }}
                >
                  {pdfs.length > 0 && <PreviewPDFView archives={pdfs} />}
                </div>
              </>
            )}
          </div>

          {/* <div className='texto-camposObligatorios'>

            <div className='row'>
              <div className='col ms-3'>
                <p className='text-guinda text-bold text-medium'>*Campos obligatorios</p>
              </div>

            </div>

          </div> */}
        </div>
      </div>
    </div>
  );
}

export default VerQuejaAdminView;
