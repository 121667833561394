import React, { createContext, useState } from "react";

export const NotificacionContext = createContext();

export const NotificacionContextProvider = ({ children }) => {
  const [notificacionState, setNotificacionState] = useState({
    notifications: [],
  });

  const closeNotification = (notification) => {
    let notifications = notificacionState.notifications.filter(
      (item) => item !== notification
    );
    setNotificacionState({ ...notificacionState, notifications });
  };

  const showNotification = (
    type,
    title,
    note,
    onContinue = () => {},
    time = null
  ) => {
    let notification = {
      show: false,
      title,
      note,
      type,
      time,
      onContinue,
    };
    let notificacions = notificacionState.notifications;

    notificacions.push(notification);
    setNotificacionState({ ...notificacionState, notificacions });
  };

  return (
    <NotificacionContext.Provider
      value={{
        notificacionState,
        setNotificacionState,
        closeNotification,
        showNotification,
      }}
    >
      {children}
    </NotificacionContext.Provider>
  );
};
