import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { GlobalContext } from "../context/global_context";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LogoMichoacan from "../assets/images/logoMichoacan.png";
import LogoGris from "../assets/images/LogoGris.png";
import Usuario from "./usuario";

export default function Menu() {
  const navigate = useNavigate();
  const { globalState, setGlobalState } = useContext(GlobalContext);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setGlobalState({ ...globalState, navBarOpen: open, hamburger: true });
  };

  const closeDrawer = () => {
    setGlobalState({ ...globalState, navBarOpen: false, hamburger: true });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" ? "auto" : 250 }}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      <div className="brand flex-column">
        <img className="LogoGuinda" src={LogoMichoacan} alt="" />
      </div>
      <Divider />

      <div
        className="menu_item"
        onClick={() => {
          closeDrawer();
          navigate("/inconformidadesRecibidas");
        }}
      >
        Inconformidades Recibidas
      </div>
      <div
        className="menu_item"
        onClick={() => {
          closeDrawer();
          navigate("/inconformidadesAtendidas");
        }}
      >
        Inconformidades Atendidas
      </div>

      <Divider />

      <ListItem
        className="cerrarSesion d-flex flex-column "
        key={"Administrador"}
        disablePadding
      >
        <div className="user  d-flex justify-content-center">
          {localStorage.getItem("token") &&
          localStorage.getItem("rol") === "Administrador" ? (
            <Usuario />
          ) : (
            <></>
          )}
        </div>

        <ListItemButton
          onClick={() => {
            navigate("/login");
            localStorage.clear();
            setGlobalState({
              ...globalState,
              navBarOpen: !globalState.navBarOpen,
              hamburger: false,
            });
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={"CERRAR SESIÓN"} />
        </ListItemButton>
      </ListItem>
    </Box>
  );

  return (
    <>
      <div>
        <Drawer
          anchor={"left"}
          open={globalState.navBarOpen}
          onClose={toggleDrawer(false)}
        >
          {list("left")}
        </Drawer>
      </div>
    </>
  );
}
