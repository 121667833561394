import React, { useState, useRef, useContext, useEffect } from "react";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import imagenLogin from "../assets/images/login.jpg";
import Slide from "@mui/material/Slide";
import Input from "@mui/material/Input";
import { NotificacionContext } from "../context/notificacion_context";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { services } from "../services/api";
import { GlobalContext } from "../context/global_context";
import { LoaderComponent } from "../components/LoaderComponent";

export default function LoginView({ login, setLogin }) {
  const { globalState, setGlobalState } = useContext(GlobalContext);
  const { showNotification } = useContext(NotificacionContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setLoader] = useState(false);

  const [register, setRegister] = useState(false);
  const containerRef = useRef(null);

  const [state, setState] = useState({
    email: "",
    password: "",
    error: "",
  });

  const handleEmail = (e) => {
    setState({ ...state, email: e.target.value });
  };

  const handlePassword = (e) => {
    setState({ ...state, password: e.target.value });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // Llama a la función que maneja el evento del botón (en este caso, apiLogin)
      apiLogin();
    }
  };

  const apiLogin = async () => {
    const email = state.email;
    const password = state.password;

    setLoader(true);

    setState({ ...state, email: state.email, password: state.password });

    if (email === "") {
      setLoader(false);
      showNotification(
        "warning",
        "Todos los campos son requeridos",
        "Ingresa un Email",
        null,
        4
      );
    } else if (password === "") {
      setLoader(false);
      showNotification(
        "warning",
        "Todos los campos son requeridos",
        "Ingresa una Contraseña",
        null,
        4
      );
    } else {
      const login = await services(
        "POST",
        `login?email=${state.email}&password=${state.password}`
      );

      if (login) {
        await localStorage.setItem("token", login.data.token);
        localStorage.setItem("rol", login.data.rol);
        setLogin(login.data.token);

        if (login.data.rol === "Administrador") {
          setGlobalState({ ...globalState, navbarOpen: true, hamburger: true });
          navigate("/inconformidadesRecibidas");
        } else if (login.data.rol === "Usuario") {
          setGlobalState({ ...globalState, navbarOpen: true, hamburger: true });
          navigate("/inconformidadesRecibidas");
        } else {
          showNotification(
            "error",
            "ups... Algo sucedió",
            "Credenciales Inválidas",
            null,
            4
          );
        }
      } else {
        setLoader(false);
      }
    }
  };
  useEffect(() => {
    if (localStorage.getItem("token")) {
      // El usuario ya está autenticado, redirigir a la página adecuada
      navigate("/status");
    }
  }, [localStorage]);

  return (
    <>
      {loader && <LoaderComponent />}
      <div className="bk-img" style={{ height: "100%" }}>
        <div className="container d-flex flex-column flex-md-row justify-content-center align-items-center py-4">
          <div className="d-flex justify-content-center">
            <img
              src={imagenLogin}
              alt="imagenLogin"
              className="imagenLogin img-fluid" 
              style={{ maxWidth: "100%", height: "auto" }} 
            />
          </div>
  
          <div className="d-flex justify-content-center flex-column cardLogin w-100" style={{ maxWidth: "400px"}}> 
            {!register && (
              <Slide
                direction="right"
                in={!register}
                container={containerRef.current}
              >
                <div>
                  <p className="text-guinda text-left font-weight-bold mt-4">
                    Bienvenido a tu Buzón
                  </p>
                  <p className="text-guinda text-left w-100">Iniciar Sesión</p>
  
                  <div className="d-flex flex-column align-items-center">
                    <FormControl
                      sx={{ m: 1, width: "100%" }}
                      variant="standard"
                    >
                      <InputLabel
                        sx={{ ml: "3px" }}
                        htmlFor="standard-adornment-email"
                      >
                        Correo
                      </InputLabel>
                      <Input
                        value={state.email}
                        name="email"
                        onChange={handleEmail}
                        id="standard-adornment-email"
                        type={"text"}
                        className="text-gray"
                      />
                    </FormControl>
                    <FormControl
                      sx={{ m: 1, width: "100%" }}
                      variant="standard"
                    >
                      <InputLabel sx={{ ml: "3px" }} htmlFor="component-simple">
                        Contraseña
                      </InputLabel>
                      <Input
                        value={state.pass}
                        onChange={handlePassword}
                        type="password"
                        name="pass"
                        id="component-simple"
                        className="text-gray"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onKeyPress={handleKeyPress}
                      />
                    </FormControl>
                  </div>
                  <div className="mt-3 d-flex flex-column justify-content-center align-items-center">
                    <div
                      className="cta_Login cta--guinda cta--mediumRadius w-100 text-center"
                      onClick={apiLogin}
                      style={{ maxWidth: "200px" }} 
                    >
                      <p className="button-text2">
                        ENTRAR
                      </p>
                    </div>
                  </div>
                </div>
              </Slide>
            )}
            {register}
          </div>
        </div>
      </div>
    </>
  );
  
}
