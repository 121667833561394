import { Modal, Box, Grid, Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ModalTextBox = ({ show, handleClose, body }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "80%" : "600px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  };

  return (
    <div>
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid item xs={12} className="text-end" sx={{ marginBottom: 2 }}>
            <Button variant="contained" onClick={handleClose}>
              <CloseIcon />
            </Button>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                id="modal-modal-description"
                sx={{
                  fontSize: "1.2rem",
                  textAlign: "center",
                  marginBottom: 2,
                  color: "#6f3439",
                }}
              >
                {body}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalTextBox;
