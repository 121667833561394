import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";

const RedirectView = () => {
  const navigate = useNavigate();
  const [redirectTimer, setRedirectTimer] = useState(null);

  const handleReturnClick = () => {
    navigate("/");
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 10000);

    setRedirectTimer(timer);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="bg-prueba container_Redirect">
      <div
        className="cta_redirect cta--rosaC2 cta--medium mt-4"
        onClick={handleReturnClick}
      >
        <p className="button-text" style={{ color: "black" }}>
          Regresar
        </p>
      </div>
      <div className="mt-4">
        <section className="contentGrid2 ">
          <div className="contentDesign col-md-6">
            <img
              className="imageHome "
              src={require("../assets/images/Header.png")}
            />
          </div>

          <div className="contentRedirect">
            <div className="containerCheckRedirect ">
              <img
                className="imageCheck "
                src={require("../assets/images/check.png")}
              />
            </div>

            <div className="cardRedirect">
              <p className="textRedirect ">
                ¡ TU REGISTRO SE HA REALIZADO <br /> CON ÉXITO !
              </p>
              <p className="textRedirect2">
                {" "}
                EN UNOS MOMENTOS, SERÁS <br /> REDIRECCIONADO AL INICIO.
                <br />
              </p>
            </div>
          </div>
          <div className="imageRedirectContain">
            <img
              className="imageRedirect2 "
              src={require("../assets/images/ComisionEstatal.png")}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default RedirectView;
