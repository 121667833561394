import React from "react";
import logoMichFooter from "../assets/images/logo-Mich.png";
import logoGobiernoDigital from "../assets/images/logo-gobierno-digital.png";
import { FaFacebookF } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { AiOutlineInstagram } from "react-icons/ai";
import IconUI from "./iu/icon_ui";

function FooterComponent() {
  return (
    <>
      <div
        id="footer"
        className="shadow-sm bg-white d-flex border-top--rosa p-4 "
      >
        <div className="container">
          <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <img className="logoGris me-4" src={logoMichFooter} alt="logo" />
              <img
                className="logoSello   mt-md-0"
                src={logoGobiernoDigital}
                alt="logo"
              />
            </div>
            <div className="d-flex flex-column align-items-center footerCenter">
              <div className="redes text-center d-flex justify-content-center mb-2 ">
                <div
                  className="me-2 icon_btn"
                  onClick={() => {
                    window.open(
                      "https://www.facebook.com/gobmichoacan/",
                      "_blank"
                    );
                  }}
                >
                  <IconUI size={"20px"}>
                    <FaFacebookF />
                  </IconUI>
                </div>
                <div
                  className="me-2 icon_btn "
                  onClick={() => {
                    window.open(
                      "https://www.instagram.com/gobmichoacan/?hl=es",
                      "_blank"
                    );
                  }}
                >
                  <IconUI size={"25px"}>
                    <AiOutlineInstagram />
                  </IconUI>
                </div>
                <div
                  className="me-2 icon_btn"
                  onClick={() => {
                    window.open("https://twitter.com/GobMichoacan", "_blank");
                  }}
                >
                  <IconUI size={"22px"}>
                    <BsTwitter />
                  </IconUI>
                </div>
              </div>
              <div className="mt-1 ">
                <div className="text-center text-small text-morado">
                  Enlaces de comunicación
                </div>
                <a target="_blank" href="AVISO_DE_PRIVACIDAD_INTEGRAL.pdf">
                  <div className="text-center enlace-login">
                    Aviso de Privacidad
                  </div>
                </a>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center mt-3 mt-md-0">
              <div className="text-morado text-small my-2">
                #HonestidadyTrabajo
              </div>
              <div className="text-morado text-small ">
                Correo electrónico de contacto:
              </div>
              <div className="text-morado text-small">
                <strong>quejacoesamm@gmail.com</strong>
              </div>
              <div className="text-morado text-small mt-2">
                Número de contacto:
              </div>
              <div className="text-morado text-small"><strong>4433176594</strong></div>
            </div>
          </div>
        </div>
      </div>
      <div className="backgrounPlaca w-100"></div>
    </>
  );
}

export default FooterComponent;
