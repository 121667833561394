import React, { useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { GlobalContext } from "../context/global_context";
import {
  maxLengthLimitInput,
  onlyNumberPhone,
  onlyLetter,
  onlyNumberLinea,
} from "../utils/inputsRules";

export default function FormularioDomicilio({ quejaSeleccionada }) {
  const { globalState, setGlobalState } = useContext(GlobalContext);

  const handleCP = (e) => {
    setGlobalState({ ...globalState, codigoPostal: e.target.value });
  };
  const handleCalle = (e) => {
    setGlobalState({ ...globalState, calle: e.target.value });
  };
  const handleEstado = (e) => {
    setGlobalState({ ...globalState, estado: e.target.value });
  };
  const handleMunicipio = (e) => {
    setGlobalState({ ...globalState, municipio: e.target.value });
  };
  const handleColonia = (e) => {
    setGlobalState({ ...globalState, colonia: e.target.value });
  };
  const handleNumExterior = (e) => {
    setGlobalState({ ...globalState, numeroExterior: e.target.value });
  };
  const handleNumInterior = (e) => {
    setGlobalState({ ...globalState, numeroInterior: e.target.value });
  };

  return (
    <div className="Formulario Paciente">
      <div className="row">
        <div className="col d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Calle:</span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.calle}
                  </span>
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="calle"
                  label="*Calle"
                  type="text"
                  error={globalState.calleError}
                  value={globalState.calle}
                  variant="standard"
                  helperText="Ingresa tu calle"
                  onChange={handleCalle}
                  onKeyPress={(event) => {
                    onlyLetter(event, 55);
                  }}
                />
              )}
            </Grid>
          </Box>
        </div>

        <div className="col  d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Número Exterior:</span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.noExterior}
                  </span>
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="numeroExterior"
                  label="*Número Exterior"
                  type="text"
                  error={globalState.numeroExteriorError}
                  value={globalState.numeroExterior}
                  variant="standard"
                  helperText="Ingresa tu numero Exterior"
                  onChange={handleNumExterior}
                />
              )}
            </Grid>
          </Box>
        </div>

        <div className="col  d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Número Interior:</span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.noInterior}
                  </span>
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="numInt"
                  label="Número Interior"
                  type="text"
                  value={globalState.numeroInterior}
                  variant="standard"
                  helperText="Ingresa tu número Interior"
                  onChange={handleNumInterior}
                />
              )}
            </Grid>
          </Box>
        </div>
      </div>

      <div className="row">
        <div className="col d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Colonia:</span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.colonia}
                  </span>
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="colonia"
                  label="*Colonia"
                  error={globalState.coloniaError}
                  value={globalState.colonia}
                  type="text"
                  variant="standard"
                  helperText="Ingresa tu colonia"
                  onChange={handleColonia}
                  onKeyPress={(event) => {
                    onlyLetter(event, 55);
                  }}
                />
              )}
            </Grid>
          </Box>
        </div>

        <div className="col d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Población:</span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.poblacion}
                  </span>
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="codigoPostal"
                  label="*Código Postal"
                  type="text"
                  error={globalState.codigoPostalError}
                  value={globalState.codigoPostal}
                  variant="standard"
                  helperText="Ingresa tu C.P."
                  onChange={handleCP}
                />
              )}
            </Grid>
          </Box>
        </div>

        <div className="col d-flex justify-content-center">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "30ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid>
              {quejaSeleccionada ? (
                <p style={{ borderBottom: "1px solid gray" }}>
                  <span className="text-morado">Código Postal:</span>
                  <span style={{ color: "black" }}>
                    {" "}
                    {quejaSeleccionada.cp}
                  </span>
                </p>
              ) : (
                <TextField
                  fullWidth
                  margin="normal"
                  id="codigoPostal"
                  label="*Código Postal"
                  type="text"
                  error={globalState.codigoPostalError}
                  value={globalState.codigoPostal}
                  variant="standard"
                  helperText="Ingresa tu C.P."
                  onChange={handleCP}
                />
              )}
            </Grid>
          </Box>
        </div>
      </div>
    </div>
  );
}
