import React, { useState, useEffect } from "react";
import DataTable from "../components/DataTable";
import { services } from "../services/api";
import Tooltip from "@mui/material/Tooltip";
import IconUI from "../components/iu/icon_ui";
import VisibilityOutlinedIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { LoaderComponent } from "../components/LoaderComponent";

function QuejasAtendidasAdmin() {
  const [quejas, setQuejas] = useState({
    listQuejas: [],
    queja: {},
  });

  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const dictionaryPlace = {
    0: "IMSS",
    1: "ISSSTE",
    2: "IMSS BIENESTAR",
    3: "Servicios Médicos",
    4: "Hospitalarios Privados",
  };

  const mostrarQuejas = async () => {
    setLoader(true);
    const api = await services("POST", `showTodo?status=1`);
    setQuejas({ ...quejas, listQuejas: api.data });
    setLoader(false);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      mostrarQuejas();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const [quejaSeleccionada, setQuejaSeleccionada] = useState({});

  const handleVerQueja = (dataIndex) => {
    const pacienteData = quejas.listQuejas[dataIndex];
    setQuejaSeleccionada(pacienteData);
    navigate("/verInconformidad", { state: pacienteData });
  };

  const columns = [
    {
      name: "nombrePaciente",
      label: "Nombre del paciente",
      options: {
        searchable: true,
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let nombre = quejas.listQuejas[dataIndex].nombrePaciente;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? " d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {nombre}
            </div>
          );
        },
      },
    },
    {
      name: "unidad",
      label: "Unidad Médica",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          const value = quejas.listQuejas[dataIndex].donde;
          const unidad = Number.isInteger(Number(value))
            ? dictionaryPlace[Number(value)]
            : value;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {unidad}
            </div>
          );
        },
      },
    },

    {
      name: "formatted_created_at",
      label: "Fecha de Creación",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let Hechos = quejas.listQuejas[dataIndex].formatted_created_at;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? " d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {Hechos}
            </div>
          );
        },
      },
    },

    {
      name: "acciones",
      label: "Acciones",

      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex, i) => (
          <>
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "w-100 d-flex align-items-center justify-content-center "
                  : "w-100 d-flex justify-content-center align-items-center "
              }
            >
              <Tooltip title="Ver protesta">
                <div
                  style={{ height: 30, width: 30, zIndex: 0 }}
                  className="cta cta--guinda cta--icon icon_btn"
                  onClick={() => {
                    handleVerQueja(dataIndex);
                  }}
                >
                  <IconUI size={"20px"} color={"white"}>
                    <VisibilityOutlinedIcon />
                  </IconUI>
                </div>
              </Tooltip>
            </div>
          </>
        ),
      },
    },
  ];

  return (
    <div className="bk-img bg-rosaAlert" style={{ height: "100vh - 80px" }}>
      <div className="container">
        {loader && <LoaderComponent />}
        <DataTable
          data={quejas.listQuejas}
          title={"Inconformidades Atendidas"}
          columns={columns}
          // more={{
          //   searchPlaceholder: "Buscar por Nombre"
          // }}
        />
      </div>
    </div>
  );
}

export default QuejasAtendidasAdmin;
