import React, { useState } from "react";
import { CardContent, Grid, Typography, Card } from "@mui/material";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import ReadingProgress from "../components/ReadingProgress";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";

const CardImagenInput = ({
  Files,
  setFiles,
  NameFiles = [],
  Description = "Desliza el Documento en formato PDF",
  FormatFiles = ".pdf",
  MensajeCarga = "cargar archivo",
  onlyOne = false,
}) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const colorUI = "#4A001F";

  const CardFile = styled(Card)(({ theme }) => ({
    height: "200px",
    width: "250px",
    margin: "auto",
    marginTop: "20px",
    marginBottom: "20px",
    borderRadius: "12px",
    boxShadow: "1px 0px 7px 0px rgba(0, 0, 0, 0.75)",
    "& .MuiCardContent-root": {
      borderColor: "#8d949e",
      borderStyle: "dotted",
      height: "80%",
      margin: "20px 20px 20px 20px",
    },
  }));

  const handleChangeFiles = (name, file) => {
    setFiles({
      ...Files,
      [name]: {
        ...Files[name],
        previewicono: URL.createObjectURL(file),
        frameicono: false,
        Archivo: file,
      },
    });
  };

  const handleDragOverFather = (event) => {
    event.preventDefault();
  };

  const handleDragEnterFather = (event, card) => {
    event.preventDefault();
    setIsDraggingOver(true);
    if (card === "file") {
      event.currentTarget.style.border = `2px dashed ${colorUI}`;
      event.currentTarget.style.filter = "blur(1px)";
    } else if (card === "content") {
    }
  };

  const handleDragLeaveFather = (event) => {
    event.preventDefault();
    setIsDraggingOver(false);

    if (!isDraggingOver) {
      event.currentTarget.style.border = "none";
      event.currentTarget.style.filter = "none";
    }
  };

  const handleDropFather = (event, name) => {
    event.preventDefault();
    setIsDraggingOver(false);

    const file = event.dataTransfer.files[0];

    handleChangeFiles(name, file);

    event.currentTarget.style.border = "";
    event.currentTarget.style.filter = "";
  };

  const handleEliminarDoc = (key) => {
    Swal.fire({
      icon: "warning",
      title: "Alerta",
      text: `Si elimina este archivo, tendrá que seleccionar uno distinto para poder guardar.`,
      buttons: ["Cancelar", "Aceptar"],
      confirmButton: true,
    }).then((result) => {
      if (result) {
        setFiles({
          ...Files,
          [key]: {
            previewicono: "",
            frameicono: true,
            Archivo: null,
          },
        });
      } else {
      }
    });
  };

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      {onlyOne
        ? NameFiles.map((item) => {
            return (
              <Grid
                container
                spacing={1}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <CardFile
                  hidden={!Files[item.key].frameicono}
                  onDrop={(event) => {
                    handleDropFather(event, item.key);
                  }}
                  onDragOver={(event) => {
                    handleDragOverFather(event);
                    handleDragEnterFather(event, "file");
                  }}
                  onDragEnter={(event) => {
                    handleDragEnterFather(event, "file");
                  }}
                  onDragLeave={handleDragLeaveFather}
                >
                  <CardContent
                    onDrop={(event) => {
                      handleDropFather(event, item.key);
                    }}
                    onDragOver={handleDragOver}
                    onDragEnter={(event) => {
                      handleDragEnterFather(event, "content");
                    }}
                  >
                    <Typography
                      sx={{ fontSize: 15, color: colorUI }}
                      color="text.secondary"
                      gutterBottom
                    >
                      {Description}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ color: colorUI, fontWeight: "bold" }}
                    >
                      {item.name}
                    </Typography>
                  </CardContent>
                </CardFile>
                {Files[item.key].frameicono ? null : (
                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <label
                      style={{
                        backgroundColor: "white",
                        color: colorUI,
                        borderRadius: "12px",
                        padding: "5px 7px",
                        marginBottom: "10px",
                      }}
                    >
                      {item.name}
                    </label>
                  </div>
                )}
                <iframe
                  id="frameiconoFiles"
                  src={Files[item.key].previewicono}
                  className="preview-iframe"
                  style={{
                    width: "70%",
                    height: "200px",
                    border: "none",
                    margin: "auto",
                    display: "block",
                    marginBottom: "20px",
                  }}
                  hidden={Files[item.key].frameicono}
                ></iframe>
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  <label
                    htmlFor={`input${item.key}`}
                    style={{
                      backgroundColor: colorUI,
                      color: "white",
                      borderRadius: "12px",
                      cursor: "pointer",
                      padding: "5px 7px",
                      marginBottom: "10px",
                    }}
                  >
                    {MensajeCarga}
                  </label>
                  <input
                    type="file"
                    id={`input${item.key}`}
                    style={{ display: "none" }}
                    accept={FormatFiles}
                    onChange={(e) => {
                      console.log(item.key);
                      handleChangeFiles(item.key, e.target.files[0]);
                    }}
                  />
                  <DeleteForeverOutlinedIcon
                    style={{ color: "red", cursor: "pointer" }}
                    fontSize="large"
                    onClick={() => {
                      handleEliminarDoc(item.key);
                    }}
                    sx={Files[item.key].frameicono ? { display: "none" } : {}}
                  />
                </div>
              </Grid>
            );
          })
        : NameFiles.map((item) => {
            return (
              <Grid item xs={12} md={4} sm={4}>
                <Grid
                  container
                  spacing={1}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <CardFile
                    hidden={!Files[item.key].frameicono}
                    onDrop={(event) => {
                      handleDropFather(event, item.key);
                    }}
                    onDragOver={(event) => {
                      handleDragOverFather(event);
                      handleDragEnterFather(event, "file");
                    }}
                    onDragEnter={(event) => {
                      handleDragEnterFather(event, "file");
                    }}
                    onDragLeave={handleDragLeaveFather}
                  >
                    <CardContent
                      onDrop={(event) => {
                        handleDropFather(event, item.key);
                      }}
                      onDragOver={handleDragOver}
                      onDragEnter={(event) => {
                        handleDragEnterFather(event, "content");
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 15, color: colorUI }}
                        color="text.secondary"
                        gutterBottom
                      >
                        {Description}
                      </Typography>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ color: colorUI, fontWeight: "bold" }}
                      >
                        {item.name}
                      </Typography>
                    </CardContent>
                  </CardFile>
                  {Files[item.key].frameicono ? null : (
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                      <label
                        style={{
                          backgroundColor: "white",
                          color: colorUI,
                          borderRadius: "12px",
                          padding: "5px 7px",
                          marginBottom: "10px",
                        }}
                      >
                        {item.name}
                      </label>
                    </div>
                  )}
                  {/* PREVISUALIZACION*/}
                  <div
                    style={{
                      textAlign: "center",
                      marginRight: Files[item.key]?.Archivo && "5px",
                      marginLeft: Files[item.key]?.Archivo && "5px",
                      height: Files[item.key]?.Archivo && "400px",
                    }}
                  >
                    {Files[item.key]?.Archivo && (
                      <ReadingProgress
                        fileUrl={Files[item.key]?.previewicono}
                        showToolbar={false}
                      />
                    )}
                  </div>
                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <label
                      htmlFor={`input${item.key}`}
                      style={{
                        backgroundColor: colorUI,
                        color: "white",
                        borderRadius: "12px",
                        cursor: "pointer",
                        padding: "5px 7px",
                        marginBottom: "10px",
                      }}
                    >
                      {MensajeCarga}
                    </label>
                    <input
                      type="file"
                      id={`input${item.key}`}
                      style={{ display: "none" }}
                      accept={FormatFiles}
                      onChange={(e) => {
                        console.log(item.key);
                        handleChangeFiles(item.key, e.target.files[0]);
                      }}
                    />
                    <DeleteForeverOutlinedIcon
                      style={{ color: "red", cursor: "pointer" }}
                      fontSize="large"
                      onClick={() => {
                        handleEliminarDoc(item.key);
                      }}
                      sx={Files[item.key].frameicono ? { display: "none" } : {}}
                    />
                  </div>
                </Grid>
              </Grid>
            );
          })}
    </Worker>
  );
};

export default CardImagenInput;
