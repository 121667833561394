import React from "react";

export default function NotPage() {
  return (
    <div
      style={{
        height: "calc(80vh - 80px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="container"
    >
      <div>
        <h1 className="mt-5">!Oops! 😢</h1>
        <h2>
          Tenemos problemas con nuestro servicio, intente más tarde... :({" "}
        </h2>
      </div>
    </div>
  );
}
