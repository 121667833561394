import { createContext, useState } from "react";

export const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({
    status: 1,
    selected: "radio2",
    curp: "",
    curpError: false,
    nombre: "",
    nombreError: "",
    apellidoPaterno: "",
    apellidoPaternoError: "",
    apellidoMaterno: "",
    apellidoMaternoError: "",
    selected4: "radio8",
    edad: "",
    fechaNacimiento: "",
    fechaNacimientoError: false,
    telefonoFijo: "",
    telefonoMovil: "",
    telefonoMovilError: false,
    telefonoOficina: "",
    email: "",
    emailError: false,
    curpRepresentante: "",
    curpRepresentanteError: false,
    nombreRepresentante: "",
    nombreRepresentanteError: false,
    aPaternoRepresentante: "",
    aPaternoRepresentanteError: false,
    aMaternoRepresentante: "",
    aMaternoRepresentanteError: false,
    selected5: "radio10",
    edadRepresentante: "",
    fechaNacimientoRepresentante: "",
    telefonoFijoRepresentante: "",
    telefonoFijoRepresentanteError: false,
    telefonoMovilRepresentante: "",
    telefonoOficinaRepresentante: "",
    emailRepresentante: "",
    emailRepresentanteError: false,
    codigoPostal: "",
    codigoPostalError: false,
    calle: "",
    calleError: false,
    estado: "",
    estadoError: false,
    municipio: "",
    municipioError: false,
    colonia: "",
    coloniaError: false,
    numeroExterior: "",
    numeroExteriorError: false,
    numeroInterior: "",
    selected2: "radio3",
    selected2Error: false,
    // otraUnidadMedica:'',
    nombreCompletoUM: "",
    nombreCompletoUMError: false,
    cpUM: "",
    cpUMError: false,
    calleUM: "",
    calleMError: false,
    estadoUM: "",
    estadoUMError: false,
    municipioUM: "",
    municipioUMError: false,
    coloniaUM: "",
    coloniaUMError: false,
    numeroExteriorUM: "",
    numeroExteriorUMError: false,
    numeroInteriorUM: "",
    telefonoFijoUM: "",
    telefonoFijoUMError: false,
    servicioMedicoUM: "",
    servicioMedicoUMError: false,
    especialidadMedicaUM: "",
    especialidadMedicaUMError: false,
    numSeguro: "",
    numSeguroError: false,
    selected3: "radio6",
    motivoQueja: "",
    motivoQuejaError: false,
    comoSucedio: "",
    comoSucedioError: false,
    fechaHechos: "",
    fechaHechosError: false,
    secuelas: "",
    secuelasError: "",
    solucion: "",
    solucionError: " ",
    navBarOpen: false,
    hamburger: false,
    readingProgress: 0,
  });

  return (
    <GlobalContext.Provider
      value={{
        globalState,
        setGlobalState,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
