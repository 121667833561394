const initialState = {
  readingProgress: 0,
};

export const clearState = () => ({
  type: "CLEAR_STATE",
});

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_READING_PROGRESS":
      return {
        ...state,
        readingProgress: action.payload,
      };
    case "CLEAR_STATE":
      return {
        ...state,
        readingProgress: 0,
      };
    default:
      return state;
  }
};

export default userReducer;
