import React, { useState, useContext, useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { GlobalContext } from "../context/global_context";
// import logo200Image from "../assets/images/logo-200-anos.png";
import logoMich from "../assets/images/logo-Mich-200.png";
import { useMediaQuery } from "@mui/material";

function HeaderComponent(props) {
  const { globalState, setGlobalState } = useContext(GlobalContext);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const token = localStorage.getItem("token");
      if (token) {
        setGlobalState({ ...globalState, hamburger: true });
      } else {
        setGlobalState({ ...globalState, navBarOpen: false, hamburger: false });
      }
    }

    return () => {
      isMounted = false;
    };
  }, []);
  const smallScreen = useMediaQuery("(min-width: 350px)");

  return (
    <div className="container-fluid shadow-sm bg-withe boderBottom--guinda py-1">
      <div className="container">
        <nav className="navbar navbar-ligth navar-expand-lg bg-white">
          <div className="headerPrincipal">
            <div className="division1">
              {globalState.hamburger ? (
                <div
                  className="ml-3 btnmenu d-flex align-content-center"
                  onClick={() => {
                    setGlobalState({
                      ...globalState,
                      navBarOpen: true,
                      hamburger: true,
                    });
                  }}
                >
                  <MenuIcon />
                </div>
              ) : (
                <></>
              )}
              <img
                className="logoMichoacan p-2"
                src={logoMich}
                alt="logo"
              />
              {smallScreen && (
              <img
                className="logoMichoacan ms-4"
                src={require("../assets/images/Inconformidad.png")}
                alt="logo"
              />
            )}
            </div>
            <div className="division2">
              <div className="text-morado ">
                Inconformidad de servicio de salud{" "}
              </div>
              <div className="imageRight">
                <img
                  className="logoMichoacan2 "
                  src={require("../assets/images/logo-gobierno-digital.png")}
                  alt="logo"
                />
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default HeaderComponent;
