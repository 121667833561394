import React, { useState } from "react";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#4A001F",
  "&:hover": {
    backgroundColor: "#4A001F",
  },
}));

function PreviewPDFView({ archives }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [titleFile, setTitleFile] = useState("");

  const isSmallScreen = useMediaQuery("(max-width:600px)"); 

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "85%" : "920px",
    height:"100%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  };

  const thumbnailPluginInstance = thumbnailPlugin();

  const handleClick = (file, name) => {
    setSelectedFile(file);
    setTitleFile(name);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedFile(null);
  };

  const removePath = (path) => {
    let newPath = path.replace("/storage/documentos/", "");
    return newPath;
  };

  return (
    <>
      <div >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
          <Grid
            container
            spacing={2}
            justifyContent="center"
            marginTop="-140px"
          >
            {archives.map((data, index) => (
              <Grid
                item
                xs={12} 
                sm={6}  
                md={4}  
                key={index}
                style={{ zIndex:"2", height: "300px", marginBottom: "40px" }}
              >
                <div style={{ position: "relative", height: "100%"}}>
                  <Viewer
                    fileUrl={removePath(data.file)}
                    defaultScale={SpecialZoomLevel.PageFit}
                    plugins={[thumbnailPluginInstance]}
                  />
                  <p className="text-center text-guinda">{data.name}</p>
                  <div
                    onClick={() =>
                      handleClick(removePath(data.file), data.name)
                    }
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      cursor: "pointer",
                      backgroundColor: "rgba(0, 0, 0, 0)",
                    }}
                  ></div>
                </div>
              </Grid>
            ))}
          </Grid>
        </Worker>
      </div>
      <Modal
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={style}>
          <Grid item xs={12} md={6} className="text-end" style={{ marginBottom: "20px" }}>
            <Button variant="contained" onClick={closeModal}>
              <CloseIcon />
            </Button>
          </Grid>
          <h3 className="text-center text-guinda">{titleFile}</h3>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <a
                rel="noreferrer"
                target="_blank"
                href={selectedFile}
                style={{ marginLeft: ".7rem" }}
              >
                <ColorButton variant="outlined" endIcon={<CloudDownloadIcon />}>
                  Descargar PDF
                </ColorButton>
              </a>
            </Grid>
          </Grid>
          {selectedFile && (
            <>
              <div style={{ height: "80%", marginTop: "1rem" }}>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                  <Viewer
                    fileUrl={selectedFile}
                    defaultScale={SpecialZoomLevel.PageFit}
                  />
                </Worker>
              </div>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default PreviewPDFView;
