import React, { useState, useContext, useEffect } from "react";
import DataTable from "../components/DataTable";
import { services } from "../services/api";
import Tooltip from "@mui/material/Tooltip";
import IconUI from "../components/iu/icon_ui";
import VisibilityOutlinedIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Button } from "@mui/material";
import { LoaderComponent } from "../components/LoaderComponent";
import { NotificacionContext } from "../context/notificacion_context";
import Swal from "sweetalert2";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
};

function StatusAdminView() {
  const [datos, setDatos] = useState({
    data: [],
  });

  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const { showNotification } = useContext(NotificacionContext);

  const dictionaryPlace = {
    0: "IMSS",
    1: "ISSSTE",
    2: "IMSS BIENESTAR",
    3: "Servicios Médicos",
    4: "Hospitalarios Privados",
  };

  useEffect(() => {
    api();
  }, []);

  const api = async () => {
    setLoader(true);
    const data = await services("POST", `showTodo?status=0`);
    setDatos({ data: data.data });
    setLoader(false);
  };

  const [stateModal, setStateModal] = useState({
    open: false,
    comentario: "",
    comentarioError: false,
    idEnvio: "",
  });

  const handleVerQueja = (dataIndex) => {
    const pacienteData = datos.data[dataIndex];
    navigate("/verInconformidad", { state: pacienteData });
  };

  const modalOpen = (id) => {
    setStateModal({ ...stateModal, open: true, idEnvio: id });
  };

  const handleClose = () => {
    setStateModal({ ...stateModal, open: false, idEnvio: "", comentario: "" });
  };

  const handleComentario = (e) => {
    setStateModal({ ...stateModal, comentario: e.target.value });
  };

  const enviarCorreo = async () => {
    if (!stateModal.comentario) {
      showNotification(
        "error",
        "Campo Requerido",
        "Por favor ingrese un comentario antes de enviar el correo",
        null,
        4
      );
      return;
    }
    setLoader(true);

    if (stateModal.idEnvio && stateModal.comentario !== "") {
      const body = {
        respuesta: stateModal.comentario,
      };

      const respuesta = await services(
        "POST",
        `ResponderQueja/${stateModal.idEnvio}`,
        body
      );

      if (respuesta.status === 200) {
        Swal.fire({
          title: "Correo Enviado",
          text: `${respuesta.data.message}`,
          icon: "success",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#4A001F",
        });
        actualizarStatus(stateModal.idEnvio);
        handleClose();
      } else {
        showNotification(
          "error",
          "ups... Algo sucedió",
          "Error al enviar correo de respuesta",
          null,
          4
        );
      }
    } else {
      showNotification(
        "error",
        "Error",
        "No se ha seleccionado una protesta válida para enviar correo",
        null,
        4
      );
    }
    setLoader(false);
  };

  const actualizarStatus = (id) => {
    const quejasActualizadas = datos.data.filter((item) => item.todo_id !== id);
    setDatos({ data: quejasActualizadas });
  };

  const columns = [
    {
      name: "nombrePaciente",
      label: "Nombre del paciente",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let nombre = datos.data[dataIndex].nombrePaciente;
          return (
            <div
              style={{
                height: "60px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              {nombre}
            </div>
          );
        },
      },
    },
    {
      name: "unidad",
      label: "Unidad Médica",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          const value = datos.data[dataIndex].donde;
          const unidad = Number.isInteger(Number(value))
            ? dictionaryPlace[Number(value)]
            : value;
          return (
            <div
              style={{ height: "60px" }}
              className={
                i % 2 === 0
                  ? "d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              {unidad}
            </div>
          );
        },
      },
    },
    {
      name: "formatted_created_at",
      label: "Fecha de Creación",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let Hechos = datos.data[dataIndex].formatted_created_at;
          return (
            <div
              style={{
                height: "60px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              {Hechos}
            </div>
          );
        },
      },
    },
    {
      name: "acciones",
      label: "Acciones",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <div
            style={{
              height: "60px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Tooltip title="Ver Inconformidad">
              <div
                style={{ height: 30, width: 30, zIndex: 0 }}
                className="cta cta--guinda cta--icon icon_btn"
                onClick={() => {
                  handleVerQueja(dataIndex);
                }}
              >
                <IconUI size={"20px"} color={"white"}>
                  <VisibilityOutlinedIcon />
                </IconUI>
              </div>
            </Tooltip>
            <Tooltip title="Responder protesta" className="ms-2">
              <div
                style={{ height: 30, width: 30, zIndex: 0 }}
                className="cta cta--guinda cta--icon icon_btn"
                onClick={() => {
                  modalOpen(datos.data[dataIndex].todo_id);
                }}
              >
                <IconUI size={"20px"} color={"white"}>
                  <EmailIcon />
                </IconUI>
              </div>
            </Tooltip>
          </div>
        ),
      },
    },
  ];

  return (
    <div className="bk-img">
      <div className="container">
        {loader && <LoaderComponent />}
        <div className="mb-5">
          <DataTable
            data={datos.data}
            title={"Inconformidades Recibidas"}
            columns={columns}
            more={{
              searchPlaceholder: "Buscar por Nombre",
            }}
          />
        </div>
        {/* Modal Respuesta */}
        <Modal
          open={stateModal.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleModal}>
            <Grid item xs={12} sm={12} md={12}>
              <h4>Comentario de respuesta</h4>
              <hr />
              <div className="mt-3">
                <TextareaAutosize
                  aria-label="minimum height"
                  value={stateModal.comentario}
                  minRows={3}
                  placeholder="Escribe tu respuesta"
                  style={{ width: "100%" }}
                  onChange={handleComentario}
                />
                <div className="d-flex justify-content-end mt-3">
                  <Grid xs={6}>
                    <Button
                      variant="contained"
                      className="cta--guinda"
                      onClick={enviarCorreo}
                    >
                      Enviar
                    </Button>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default StatusAdminView;
