import React, { useState, useContext } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';


export default function Usuario() {

    
   

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
      };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="d-flex flex-row  align-items-center">
            <p style={{ margin: 5 }}>ADMIN</p>
            <IconButton
                size="small"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                disableRipple
                color="inherit"
            >
                {/* <AccountCircle /> */}
                <AccountCircleIcon fontSize="large" />
                {
                    // (anchorEl !== null) ?
                    //     <KeyboardArrowUpIcon />
                    //     :
                    //     <KeyboardArrowDownIcon />
                }
            </IconButton>
            {/* <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >   
                <MenuItem >Mi Perfil</MenuItem>
                <MenuItem >Cerrar Session</MenuItem>
            </Menu> */}
        </div>
    )
}
