import "./style/estilos.css";
import "./style/estilosMobil.css";

import { BrowserRouter as Router, Navigate, useRoutes } from "react-router-dom";
import { GlobalContextProvider } from "./context/global_context";
import { NotificacionContextProvider } from "./context/notificacion_context";
import HeaderComponent from "./components/HeaderComponent";
import FooterComponent from "./components/FooterComponent";
import NotificacionUi from "./components/iu/notificacion_ui";
import LoginView from "./views/LoginView";
import StatusAdminView from "./views/StatusAdminView";
import Menu from "./components/menu";
import VerQuejaAdminView from "./views/VerQuejaAdminView";
import PrivateRoutes from "./components/privateRoutes";
import { useEffect, useState } from "react";
import QuejasAtendidasAdmin from "./views/QuejasAtendidasAdmin";
import InconformidadView from "./views/InconformidadView";
import NotPage from "./views/NotPage";
import RedirectView from "./views/RedirectView";
import HomeView from "./views/HomeView";

function App() {
  const [login, setLogin] = useState(localStorage.getItem("token"));

  const RouterApp = () => {
    return useRoutes([
      { path: "/", element: <HomeView /> },
      { path: "/redireccionando", element: <RedirectView /> },
      { path: "/presentarInconformidad", element: <InconformidadView /> },
      {
        path: "/login",
        element: <LoginView login={login} setLogin={setLogin} />,
      },
      {
        path: "/inconformidadesRecibidas",
        element: (
          <PrivateRoutes>
            <StatusAdminView />
          </PrivateRoutes>
        ),
      },
      {
        path: "/verInconformidad",
        element: (
          <PrivateRoutes>
            <VerQuejaAdminView />
          </PrivateRoutes>
        ),
      },
      {
        path: "/inconformidadesAtendidas",
        element: (
          <PrivateRoutes>
            <QuejasAtendidasAdmin />
          </PrivateRoutes>
        ),
      },
      { path: "/500", element: <NotPage /> },
      { path: "*", element: <Navigate to="/inconformidadesRecibidas" /> },
    ]);
  };

  return (
    <div className="App">
      <GlobalContextProvider>
        <NotificacionContextProvider>
          <Router>
            <HeaderComponent />
            <NotificacionUi />
            {login && <Menu />}
            <RouterApp />
            <FooterComponent />
          </Router>
        </NotificacionContextProvider>
      </GlobalContextProvider>
    </div>
  );
}

export default App;
